import { Link } from "gatsby"
import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import ImageFooterDomeInvalides from "./imageFooterDomeInvalides"
import { MdPhone, MdLocationOn } from "react-icons/md"

const FooterBlock = ({ ...props }) => (
  <Footer>
    <FlexWrapper>
      <FlexItem>
        <Title>Contactez-nous</Title>
        <Address>
          <b>Consultation</b>
          <br />
          Espace Médical Vauban
          <br />
          2A Avenue de Ségur
          <br />
          75 007 Paris
          <br />
          <A href="tel:+33153598809">
            <MdPhone
              css={css`
                transform: translateY(1px);
              `}
            />{" "}
            01 53 59 88 09
          </A>
          <br />
          <Link
            to="/contact/"
            css={css`
              text-decoration: none;
            `}
          >
            <MdLocationOn
              css={css`
                transform: translateY(1px);
              `}
            />{" "}
            Plan d'accès
          </Link>
        </Address>
      </FlexItem>
      <FlexItem>
        <Address
          css={css`
            margin-top: 42px;
            strong,
            b {
              letter-spacing: 0.04em;
            }
            @media (max-width: 678px) {
              margin-top: 25px;
            }
          `}
        >
          <b>
            Intervention{" "}
            <span
              css={css`
                @media (max-width: 1200px) {
                  display: none;
                }
              `}
            >
              chirurgicale
            </span>
          </b>
          <br />
          Clinique du sport
          <br />
          36 Boulevard Saint Marcel
          <br />
          75 005 Paris
          <br />
          <A href="tel:+33186867505">
            <MdPhone
              css={css`
                transform: translateY(1px);
              `}
            />{" "}
            01 86 86 75 05
          </A>
          <br />
          <Link
            to="/contact/"
            css={css`
              text-decoration: none;
            `}
          >
            <MdLocationOn
              css={css`
                transform: translateY(1px);
              `}
            />{" "}
            Plan d'accès
          </Link>
        </Address>
      </FlexItem>
      <FlexItem>
        <Title
          css={css`
            @media (max-width: 678px) {
              margin-top: 25px;
            }
          `}
        >
          Interventions
        </Title>
        <Ul>
          <Li>
            <Link to="/chirurgie-coude/">Chirurgie du coude</Link>
          </Li>
          <Li>
            <Link to="/chirurgie-epaule/">Chirurgie de l'épaule</Link>
          </Li>
          <Li>
            <Link to="/chirurgie-main/">Chirurgie de la main</Link>
          </Li>
          <Li>
            <Link to="/chirurgie-nerfs/">Chirurgie des nerfs</Link>
          </Li>
          <Li>
            <Link to="/chirurgie-poignet/">Chirurgie du poignet</Link>
          </Li>
          <Li>
            <Link to="/chirurgie-du-sport/">Chirurgie du sport</Link>
          </Li>
          <Li>
            <Link to="/techniques/">Techniques pratiquées</Link>
          </Li>
          <Li />
        </Ul>
      </FlexItem>
      <FlexItem>
        <Title
          css={css`
            @media (max-width: 678px) {
              margin-top: 25px;
            }
          `}
        >
          Rubriques
        </Title>
        <Ul>
          <Li>
            <Link to="/chirurgien-main-coude-epaule-paris/">Présentation</Link>
          </Li>
          <Li>
            <Link to="/consultation/">Consultation</Link>
          </Li>
          <Li>
            <Link to="/hospitalisation/">Hospitalisation</Link>
          </Li>
          <Li>
            <Link to="/equipe/">L'équipe</Link>
          </Li>
          <Li>
            <Link to="/actus/">Actualités</Link>
          </Li>
          <Li>
            <Link to="/contact/">Contact</Link>
          </Li>
          <Li>
            <Link to="/videos/">Vidéos & Émissions TV</Link>
          </Li>
          <Li />
        </Ul>
      </FlexItem>
    </FlexWrapper>
    <Mention>
      <ImageFooterDomeInvalides />
      <p>
        Le Docteur Philippe Roure, chirurgien orthopédiste à Paris, spécialisé
        en chirurgie de la main, du bras et de l'ensemble du membre supérieur
        (épaule, coude, poignet). Ce site internet est destiné à présenter le Dr
        Roure, son cabinet, ses pratiques médicales, ainsi qu’à l'information de
        ses patients. L'information proposée sur ce site ne dispense en aucun
        cas d'une consultation avec un médecin ou un chirurgien. Pour plus
        d'informations, vous pouvez{" "}
        <Link
          to="/contact/#rdv"
          css={css`
            text-decoration: none;
            font-weight: 600;
            :hover {
              background-color: hsla(193, 5%, 62%, 0.2);
              border-color: hsla(193, 5%, 62%, 0.2);
            }
          `}
        >
          prendre rendez-vous
        </Link>
        .
      </p>
      <p
        css={css`
          font-weight: 500;
          letter-spacing: 0.033em;
          margin-top: 14px;
        `}
      >
        Dr Roure - Chirurgien orthopédiste à Paris © 2025 |{" "}
        <Link
          to="/mentions-legales/"
          css={css`
            border-bottom: none;
            padding: 0.5em 0.5em 0.5em 0.2em;
          `}
        >
          Mentions légales
        </Link>{" "}
        |{" "}
        <Link
          to="/contact/"
          css={css`
            border-bottom: none;
            padding: 0.5em 0.5em 0.5em 0.2em;
          `}
        >
          Contactez-nous
        </Link>{" "}
        |{" "}
        <Link
          to={props.pathEn}
          css={css`
            border-bottom: none;
            padding: 0.5em 0.5em 0.5em 0.2em;
          `}
        >
          <span role="img" aria-label="English version">
            🇬🇧
          </span>
          &nbsp;English version
        </Link>
      </p>
    </Mention>
  </Footer>
)

export default FooterBlock

const Footer = styled.footer`
  background-color: rgba(44, 44, 44, 0.98);
  padding: 2.5em 2.5em;
  a {
    color: white;
  }
  @media (max-width: 678px) {
    padding: 25px 23px 115px;
  }
`

const FlexWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  display: flex;
  margin: 0 auto;
  @media (max-width: 678px) {
    display: block;
  }
`

const FlexItem = styled.div`
  flex: 25%;
  color: white;
  @media (max-width: 678px) {
    flex: 50%;
  }
`

const Title = styled.div`
  color: white;
  font-weight: 500;
  font-size: 1.2em;
  letter-spacing: 0.05em;
  margin: 0 0 0.75em;
  text-transform: uppercase;

  @media (max-width: 1200px) {
    font-size: 1em;
  }
`

const Address = styled.div`
  font-size: 0.88em;
  line-height: 2.273em;
`

const Ul = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`

const Li = styled.li`
  font-size: 0.88em;
  margin: 0.35em 0;
  a {
    padding: 0.65em 0;
    text-decoration: none;
  }
`

const Mention = styled.div`
  width: 100%;
  color: white;
  max-width: 1150px;
  margin: 0 auto;
  font-size: 0.86em;
  line-height: 1.65em;
  padding-top: 1em;
  p {
    opacity: 0.8;
  }
`

const A = styled.a`
  border: none;
  text-decoration: none;
  :hover {
    background-color: hsla(193, 5%, 62%, 0.2);
    border-color: hsla(193, 5%, 62%, 0.2);
  }
`
